import {
  MDBCard,
  MDBCardBody,
  MDBCol,
  MDBCollapse,
  MDBContainer,
  MDBIcon,
  MDBNavbarToggler,
  MDBRow,
} from 'mdbreact'
import React, { useState } from 'react'

import { Link } from 'gatsby'
import styled from 'styled-components'

const MDBCardCompMenu = styled(MDBCard)`
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
  left: 50%;
  top: 14px !important;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1;
`

const MDBCardCompMobile = styled(MDBCard)`
  width: 100%;
  margin: 0px auto;
  padding: 0px auto;
  text-align: center;
  position: fixed;
  top: inherit !important;
  bottom: 70px;
  left: 50%;
  top: 14px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 100;
  .navbar-toggler {
    outline: none;
    padding: 0px !important;
    color: #575549;
    span {
      width: 1em;
      height: 1em;
    }
  }
`
const MDBCardBodyComp = styled(MDBCardBody)`
  text-align: left;
  @media only screen and (max-width: 767px) {
    padding-top: 0px;
  }
  ul {
    width: 100%;
    margin: 0px;
    padding: 0px;
    list-style-position: outside;
    list-style: none;
    li {
      width: 100%;
      background: none;
      border-bottom: 1px #ccc solid;
      padding: 0em 0.1em;
      margin: 0 auto;
      display: inline-block;
      a {
        color: #575549;
        font-size: 0.8em;
      }
      li {
        padding: 0em 0.4em;
        border-bottom: none;
      }
    }
  }
`

function Rightmenu() {
  const [collapseID, setCollapseID] = useState(false)

  const toggleSingleCollapse = (collapseId) => {
    setCollapseID(!collapseId)
  }

  return (
    <>
      {/* Desktop Menu */}
      <div className="d-none d-md-inline m-0 p-0">
        <Link to="/findlocations" className="red-text-Genesis text-center">
          <MDBCardCompMenu className="font-weight-bold my-0 p-2">
            <MDBIcon
              icon="fas fa-map-marked-alt"
              size="3x"
              className="red-text-Genesis my-2"
            />
            <h5>Find a Location</h5>
          </MDBCardCompMenu>
        </Link>

        <a
          href="/contact"
          className="ap-open text-center"
          role="button"
          tabIndex="0"
        >
          <MDBCardCompMenu className="my-2">
            <MDBCardBodyComp className="text-center m-0 p-2">
              <MDBIcon
                icon="fas fa-info-circle"
                size="3x"
                className="black-text-Genesis m-0 p-0"
              />
              <p className="gray-text-Genesis justify my-2">
                We understand that finding the right care for your loved one can
                be difficult. Contact us now to speak to an admissions
                specialist regarding your unique needs!
              </p>
              <h5 className="font-weight-bold my-3 black-text-Genesis gray-shadow">
                Get in Touch Now
              </h5>
            </MDBCardBodyComp>
          </MDBCardCompMenu>
        </a>
      </div>

      {/* Mobile Menu */}
      <MDBCardCompMobile className="d-inline d-md-none">
        <MDBNavbarToggler
          id="mobilemenu"
          title="mobilemenu"
          aria-label="mobilemenu"
          aria-labelledby="mobilemenu"
          onClick={() => toggleSingleCollapse(collapseID)}
        >
          <MDBCollapse isOpen={collapseID}>
            <MDBIcon icon="fas fa-chevron-down" size="2x" />
          </MDBCollapse>
          <MDBCollapse isOpen={!collapseID}>
            <MDBIcon icon="fas fa-chevron-up" size="2x" />
          </MDBCollapse>
        </MDBNavbarToggler>
        <MDBCollapse isOpen={collapseID} navbar>
          <MDBCardBody className="m-0 py-0 px-1 text-center">
            <MDBContainer className="my-0 py-0 text-center">
              <MDBRow className="m-0 p-0 text-center">
                <MDBCol col="6" className="m-0 p-0 text-center">
                  <Link to="/findlocations" className=" text-center">
                    <MDBIcon
                      icon="fas fa-map-marked-alt"
                      size="3x"
                      className="red-text-Genesis m-0 p-0"
                    />
                    <h5 className="font-weight-bold m-0 p-0 red-text-Genesis gray-shadow">
                      Find a Location
                    </h5>
                  </Link>
                </MDBCol>
                <MDBCol col="6" className="m-0 p-0 text-center">
                  <a className="ap-open text-center" role="button" tabIndex="0">
                    <MDBIcon
                      icon="fas fa-info-circle"
                      size="3x"
                      className="black-text-Genesis m-0 p-0"
                    />
                    <h5 className="font-weight-bold m-0 p-0 black-text-Genesis gray-shadow">
                      Chat with Us
                    </h5>
                  </a>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBCardBody>
        </MDBCollapse>
      </MDBCardCompMobile>
    </>
  )
}

export default Rightmenu
