import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import BackLink from '../../components/backlink';
import BreadcrumbMenu from '../../components/menus/corpresourcesbcmenu';
import InlineBodyTransform from '../../components/inlinebodytransform';
import Layout from '../../components/layout';
import Navbar from '../../components/menus/resourcesnavbarmenu';
import React from 'react';
import SEO from '../../components/seo';
import { graphql } from 'gatsby';

let gmdv = require(`../../ghc_config`);

/** Fixed Metatags */

const Detail = ({ data }) => {

  const post = data.nodeResourcesCorp

  let dateString = gmdv.dateProcessed(post.field_happening_s_date)

  dateString = dateString.toLocaleString('en-us', { 
    month: 'numeric', 
    day: 'numeric', 
    year: 'numeric',
  });

  let titleTag = ``
  if(post.field_met) {
    titleTag = post.field_met.title
  } else {
    titleTag = post.title
  }
  titleTag += ` - Resources`

  return (
    <Layout>
      <SEO 
        title={titleTag}
        description={post.field_met ? post.field_met.description : post.title}
        keywords={post.field_met ? [post.field_met.keywords] : [post.title]}
      />
      <main>
        <MDBContainer tag="section" id="maincontent">
          <MDBRow center>
            <MDBCol xs="12" md="3" lg="3" className="col-12 leftside">
              <Navbar />
            </MDBCol>
            <MDBCol xs="12" md="9" lg="9" className="col-12 contentRight">
            <h1 className="pagetitle" 
                style={{backgroundImage: `url(${'/images/banners/SectionsBox/Healthcare_resources.png'})`}}>
                Resources
              </h1>
              <BreadcrumbMenu title={post.title}/>
              <BackLink/>
              <small>{dateString}</small>
              <InlineBodyTransform bodyValue={post.body} />
              <BackLink bottom/>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </main>
    </Layout>
  )
}

export default Detail;

export const query = graphql`
  query($id: String!) {
    nodeResourcesCorp(id: { eq: $id }) {
      field_happening_s_date
      title
      body {
        value
        summary
      }
      field_met {
        title
        description
        keywords
      }
      path {
        alias
      }
    }
  }
`