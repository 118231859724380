import { MDBCard, MDBIcon } from 'mdbreact';

import React from 'react';

const backlink = (props) => {

  let content = (
    <MDBCard 
      onClick={() => {typeof history !== 'undefined' && history.go(-1)}}
      className="backLink text-nowrap">
        <h5 className="px-1 py-0 m-0">
            <MDBIcon icon="angle-double-left" size="1x" className="p-0"/> back
        </h5>
    </MDBCard>
  )
  if (props.bottom) {content = (<><br/><br/>{content}</>)}

  return (content)
}

export default backlink;